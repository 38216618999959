import heic2any from "heic2any";
import { message } from 'ant-design-vue'

// 提示语判断早中晚
export function FunTimePrompt(){
  const now = new Date(),hour = now.getHours();
  let text = ''
  if(hour < 6){
    text = '凌晨好'
  }else if (hour < 9){
    text = '早上好'
  }else if (hour < 12){
    text = '上午好'
  }else if (hour < 14){
    text = '中午好'
  }else if (hour < 17){
    text = '下午好'
  }else if (hour < 19){
    text = '傍晚好'
  }else if (hour < 22){
    text = '晚上好'
  }else{
    text = '夜里好'
  }
  return text
}

// 时间转换 - 秒转时分秒
export function formatSeconds(value) {
  if(!value)return '00:00'
  let result = parseInt(value)
  let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

  let res = '';
  if(h !== '00') res += `${h}:`;
  res += `${m}:`;
  res += `${s}`;
  return res;
}

// 金额增加千位符
export function onThousand(e){
  e = Number(e)
  return e.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}

// 生成一段uuid
export function uuid(){
  let d = new Date().getTime()
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now() // 如果可用，请使用高精度计时器
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
};

// 价格处理
export function managePrice(con) {
  let txt = (con * 1).toFixed(2).split('.')
  return txt
}


var loading = false
// 万能打开附件
export function openFile(url){
  const isHEIC = url.endsWith('.heic');

  // 区分是否是heic文件
  if (isHEIC) {
    if(loading) return
    loading = true
    message.loading({ content: "HEIC图片在线预览解析中...", duration: 0 });
    viewHeic(url).then((res)=>{
      window.open(res);
      message.success();
      loading = false
    })
  } else {
    let lastIndex = url.lastIndexOf('.');
    let suffix = url.substring(lastIndex+1)
    if(suffix == 'doc' || suffix == 'docx' || suffix == 'txt' || suffix == 'xls' || suffix == 'xlsx'){
      window.open(url + '?ci-process=doc-preview&dstType=html&copyable=0&htmlParams=eyJwZGZPcHRpb25zIjp7ImlzSW5TYWZlTW9kZSI6dHJ1ZX19');
    } else {
      window.open(url);
    }
  }
}

// 解析预览heic图片文件
export async function viewHeic(url){
  const isHEIC = url.endsWith('.heic');
  if (isHEIC) {
    const response = await fetch(url);
    const blob = await response.blob();
    const imageBlob = await heic2any({ blob });
    const imageUrl = URL.createObjectURL(imageBlob);
    return imageUrl
  } else {
    return url
  }
}

// 附件文件解析后缀并返回对应图片
export function getSuffix(string){
  let docIcon = require('@/assets/image/homework/icon-doc.png')
  let imageIcon = require('@/assets/image/homework/icon-img.png')
  let txtIcon = require('@/assets/image/homework/icon-txt.png')
  let pdfIcon = require('@/assets/image/homework/icon-pdf.png')
  let mp4Icon = require('@/assets/image/homework/icon-mp4.png')
  let xlsIcon = require('@/assets/image/homework/icon-xls.png')
  if(string){
    let lastIndex = string.lastIndexOf('.');
    let suffix = string.substring(lastIndex+1)
    let imgSuffix = ['jpg','png','gif','jpeg','heic']
    let xlsSuffix = ['xls','xlsx']
    let imgIcon = ''
    if(suffix == 'doc' || suffix == 'docx'){
      imgIcon = docIcon
    } else if (suffix == 'txt'){
      imgIcon = txtIcon
    } else if (suffix == 'pdf'){
      imgIcon = pdfIcon
    } else if (suffix == 'mp4' || suffix == 'MP4'){
      imgIcon = mp4Icon
    } else if (imgSuffix.indexOf(suffix) != -1){
      imgIcon = imageIcon
    } else if (xlsSuffix.indexOf(suffix) != -1){
      imgIcon = xlsIcon
    }
    return imgIcon
  }else{
    return false;
  }
}


// 文件下载功能
export function downFile(url,name){
  let fileName = name;
  let xhr = new XMLHttpRequest();
  xhr.open("get", url, true);
  xhr.setRequestHeader(
    "Content-Type",
    `application/pdf`,
    `application/msword`,
    `image/png`,
    `image/jpeg`,
    `image/gif`,
    `text/plain`,
    `application/octet-stream`,
    `text/xml`
  );
  xhr.responseType = "blob";
  xhr.onload = function () {
    if (this.status == 200) {
      //接受二进制文件流
      var blob = this.response;
      downloadExportFile(blob, fileName);
    }
  };
  xhr.send();
}

// 文件下载功能
export function downloadExportFile(blob,tagFileName){
  let downloadElement = document.createElement("a");
  let href = blob;
  if (typeof blob == "string") {
    downloadElement.target = "_blank";
  } else {
    href = window.URL.createObjectURL(blob); //创建下载的链接
  }
  downloadElement.href = href;
  //下载后文件名
  downloadElement.download = tagFileName;
  downloadElement.click(); //点击下载
  if (document.body.downloadElement) {
    document.body.removeChild(downloadElement); //下载完成移除元素
  }
  if (typeof blob != "string") {
    window.URL.revokeObjectURL(href); //释放掉blob对象
  }
}